import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Confetti from "react-confetti";

//Imagenes
import gracias from "../images/Campana/gracias.svg";
import graciaswhite from "../images/Campana/gracias-white.svg";
import graciasdoctores from "../images/Campana/gracias-doctores.svg";
import flecha from "../images/Campana/flecha-boton.svg";
import flechaAzul from "../images/Campana/flecha-boton-azul.svg";

import Redes from "./Redes";
import { routes } from "../routes/Routes";

import "../css/felicidades.scss";

const FooterDoctores = () => {
    return (
        <footer className="footer-doctores">
            <p>
                Si quiere conocer más acerca de headtag y nuestro trabajo,{" "}
                <br />
                <b>¡Visite nuestra página web y redes sociales!</b>
            </p>
            <div className="redes">
                <Redes doctores={true} />
            </div>
        </footer>
    );
};

const FooterLanding = () => {
    return (
        <footer className="footer-landing">
            <p className="conocer">
                Si quieres conocer más acerca de headtag y nuestro trabajo{" "}
                <br />
                <b>¡visita nuestra página web y redes sociales!</b>
            </p>
            <div className="redes">
                <Redes size="w-90" />
            </div>
            <p className="marca-registrada">
                Copyright 2020. Headtag es una marca registrada.
            </p>
        </footer>
    );
};

interface FelicidadesProps {
    blanco: boolean;
    doctores: boolean;
    regresar: boolean;
}

interface FelicidadesState {
    reciclado: boolean;
    height: number;
    width: number;
    background: string;
    color: string;
    confetti: boolean;
    flecha: any;
    gracias: any;
}

class Felicidades extends Component<FelicidadesProps, FelicidadesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            reciclado: true,
            height: window.innerHeight,
            width: window.innerWidth,
            background: "",
            color: "",
            flecha: null,
            confetti: true,
            gracias: graciaswhite
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.actualizarPantalla);
        document
            .getElementById("felicidades")
            ?.style.setProperty("--vh", `${this.state.height * 0.01}px`);

        setTimeout(() => {
            this.setState({ reciclado: false });
        }, 4000);

        this.setState({
            background: this.props.doctores
                ? "bg-azul"
                : this.props.blanco
                ? "bg-blanco"
                : "bg-fondoAzul"
        });

        this.setState({
            color: this.props.doctores ? "" : this.props.blanco ? "cl-azul" : ""
        });

        this.setState({
            confetti: !this.props.doctores
        });

        this.setState({
            flecha: this.props.doctores
                ? flecha
                : this.props.blanco
                ? flechaAzul
                : flecha
        });

        this.setState({
            gracias: this.props.doctores
                ? graciasdoctores
                : this.props.blanco
                ? gracias
                : graciaswhite
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.actualizarPantalla);
        document.body.style.overflowX = "hidden";
    }

    actualizarPantalla = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        document
            .getElementById("felicidades")
            ?.style.setProperty("--vh", `${this.state.height * 0.01}px`);
    };

    render() {
        return (
            <div
                id="felicidades"
                className={
                    this.props.doctores
                        ? `felicidades-doctores`
                        : `definicion-felicidades ${this.state.background}`
                }
            >
                {this.state.confetti ? (
                    <Confetti
                        width={this.state.width - 14}
                        height={this.state.height}
                        recycle={this.state.reciclado}
                    />
                ) : null}
                <div className="flecha">
                    {this.props.regresar ? (
                        <Link to="/landing" className="mensaje-regresar">
                            <img src={this.state.flecha} alt="flecha" />
                            <p className={`${this.state.color}`}>Regresar</p>
                        </Link>
                    ) : (
                        <Link to={routes.Inicio.link} className="mensaje-home">
                            <p className={`${this.state.color}`}>
                                Ir a Headtag
                            </p>
                            <img src={this.state.flecha} alt="flecha" />
                        </Link>
                    )}
                </div>
                {this.props.doctores ? (
                    <div className="arreglo">
                        <div className="definicion-mensaje">
                            <div className="definicion-linea" />
                            <img src={graciasdoctores} alt="Gracias" />
                        </div>
                        <div className="recibido">
                            <h1>
                                Hemos recibido sus datos y nos pondremos en
                                contacto con usted menos de 24 horas.
                            </h1>
                        </div>
                    </div>
                ) : (
                    <div className="definicion-mensaje">
                        <img src={this.state.gracias} alt="Gracias" />
                        <h1 className={`${this.state.color}`}>
                            Hemos recibido tus datos <br /> y nos pondremos en
                            contacto <br /> contigo en menos de 24 horas
                        </h1>
                    </div>
                )}

                {this.props.doctores ? <FooterDoctores /> : <FooterLanding />}
            </div>
        );
    }
}

export default Felicidades;
