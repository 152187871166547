import React, { Component } from "react";
import Helmet from "react-helmet";

import "../css/landing.scss";

import banner from "../images/Campana/banner.png";
import Pregunta from "../images/Cultura.svg";
import dweblogo from "../images/web.svg";
import seologo from "../images/seo.svg";
import designlogo from "../images/diseno.svg";
import cmlogo from "../images/pantalla.svg";
import oportunidad from "../images/Campana/oportunidad.png";
import estrellas from "../images/Campana/estrellas.svg";
import laescuadra from "../images/Campana/laescuadra.svg";
import pv from "../images/Campana/pv.svg";
import integramente from "../images/Campana/integramente.svg";
import espacio from "../images/Campana/espacio.svg";
import darfi from "../images/Campana/darfi.svg";
import arreglamos from "../images/Campana/arreglamos.svg";
import Redes from "./Redes";
import Felicidades from "./Felicidades";
import { routes } from "../routes/Routes";

export default class Landing extends Component {
    state = {
        formEnviada: false,
        values: {
            nombre: "",
            correo: "",
            telefono: "",
            comentarios: "",
            servicio: "SEO/SEM"
        }
    };

    render() {
        const servicio = "landing-diseno";
        if (this.state.formEnviada) {
            return (
                <main id="landing">
                    <Helmet>
                        <meta name="robots" content="noindex" />
                        <meta name="googlebot" content="noindex" />

                        <title>{routes[servicio].title}</title>
                        <link
                            rel="canonical"
                            href={routes[servicio].canonical}
                        />
                        <meta
                            name="description"
                            content={routes[servicio].description}
                        />
                        <meta name="revisit-after" content="7 days" />
                        <meta name="rating" content="General" />
                        <meta name="location" content="México" />
                        <meta
                            name="classification"
                            content="Agencia de diseño | Posicionamiento web | Apps móviles"
                        />
                    </Helmet>
                    <Felicidades
                        blanco={true}
                        doctores={false}
                        regresar={false}
                    />
                </main>
            );
        }
        return (
            <main id="landing">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <meta name="googlebot" content="noindex" />

                    <title>{routes[servicio].title}</title>
                    <link rel="canonical" href={routes[servicio].canonical} />
                    <meta
                        name="description"
                        content={routes[servicio].description}
                    />
                    <meta name="revisit-after" content="7 days" />
                    <meta name="rating" content="General" />
                    <meta name="location" content="México" />
                    <meta
                        name="classification"
                        content="Agencia de diseño | Posicionamiento web | Apps móviles"
                    />
                </Helmet>
                <section className="contacto">
                    <div className="dividido">
                        <div className="texto">
                            <h1>
                                ¡Da a conocer tu empresa en el mundo digital con
                                una página web!
                            </h1>
                            <p>Si tu negocio no está en internet, no existe.</p>
                        </div>
                        <form
                            id="diseno-arriba"
                            className="form"
                            onSubmit={this.onSubmit}
                        >
                            <h2>Déjanos tus datos</h2>
                            <label htmlFor="nombre">Nombre y apellido*: </label>
                            <input
                                type="text"
                                name="nombre"
                                value={this.state.values.nombre}
                                onChange={e =>
                                    this.onChange(e.target, "nombre")
                                }
                                required
                            />
                            <label htmlFor="correo">Correo*:</label>
                            <input
                                type="email"
                                name="correo"
                                value={this.state.values.correo}
                                onChange={e =>
                                    this.onChange(e.target, "correo")
                                }
                                required
                            />
                            <label htmlFor="telefono">Teléfono*: </label>
                            <input
                                type="tel"
                                name="telefono"
                                value={this.state.values.telefono}
                                onChange={e =>
                                    this.onChange(e.target, "telefono")
                                }
                                required
                                pattern="[0-9]{10}"
                            />
                            <label htmlFor="servicio">
                                Servicio que te interesa:{" "}
                            </label>
                            <select
                                name="servicio"
                                id="servicio"
                                value={this.state.values.servicio}
                                onChange={e =>
                                    this.onChange(e.target, "servicio")
                                }
                                required
                            >
                                <option value="Diseño Web">Diseño Web</option>
                                <option value="SEO / SEM">SEO / SEM</option>
                                <option value="Diseño Gráfico">
                                    Diseño Gráfico
                                </option>
                                <option value="Community Management">
                                    Community Management
                                </option>
                                <option value="Ilustración">Ilustración</option>
                            </select>
                            <label htmlFor="comentarios">
                                Comentarios (opcional):{" "}
                            </label>
                            <textarea
                                name="comentarios"
                                id="comentarios"
                                value={this.state.values.comentarios}
                                onChange={e =>
                                    this.onChange(e.target, "comentarios")
                                }
                            ></textarea>
                            <div className="boton-enviar">
                                <button type="submit">Enviar</button>
                            </div>
                        </form>
                    </div>
                </section>
                <section className="pregunta">
                    <div className="texto">
                        <h2>¿No tienes página web?</h2>
                        <hr />
                        <p>
                            Si buscan a tu empresa en internet y no la
                            encuentran, te estás perdiendo de muchos clientes
                            potenciales y dañando la reputación de tu marca.
                        </p>
                    </div>
                    <img src={Pregunta} alt="" />
                </section>
                <section className="banner">
                    <div className="background-container">
                        <h2>
                            Brinda confianza y genera más clientes con un sitio
                            web.
                        </h2>
                        <img src={banner} alt="Banner" />
                    </div>
                </section>
                <section className="servicios">
                    <div className="service-container">
                        <div className="diseno">
                            <img src={designlogo} alt="" />
                            <div>
                                <h2>
                                    Diseño
                                    <br />
                                    personalizado
                                </h2>
                                <p>
                                    Haremos el diseño de tu página web de
                                    acuerdo a tus necesidades, objetivos y la
                                    identidad de la empresa.
                                </p>
                            </div>
                        </div>
                        <div className="auto">
                            <img src={dweblogo} alt="" />
                            <div>
                                <h2>
                                    Plataforma
                                    <br />
                                    autoadministrable
                                </h2>
                                <p>
                                    Podrás hacer cambios de texto, imágenes y
                                    colores de forma muy sencilla.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="linea" />
                    <div className="service-container">
                        <div className="disponible">
                            <img src={cmlogo} alt="" />
                            <div>
                                <h2>
                                    Disponible en cualquier
                                    <br />
                                    dispositivo
                                </h2>
                                <p>
                                    Tu página web se verá bien en computadoras,
                                    laptops, celulares y tabletas.
                                </p>
                            </div>
                        </div>
                        <div className="alamano">
                            <img src={seologo} alt="" />
                            <div>
                                <h2>
                                    Información <br />a la mano
                                </h2>
                                <p>
                                    Tus clientes podrán encontrar fácilmente tus
                                    productos, servicios, ubicación y datos de
                                    contacto.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="razones">
                    <div className="blue-banner">
                        <h2>Vende hasta cuando duermes</h2>
                        <p>
                            Tu página web estará disponible 24/7/365, por lo que
                            podrás atraer prospectos a cualquier hora.
                        </p>
                    </div>
                    <div className="white-banner">
                        <p>
                            Es momento que aproveches todas las ventajas del
                            internet.
                        </p>
                        <h2>¡No te quedes atrás!</h2>
                    </div>
                    <img src={oportunidad} alt="" />
                </section>
                <section className="clientes">
                    <div className="clientes-mosaico">
                        <img src={laescuadra} alt="Cliente La Escuadra" />
                        <img src={pv} alt="Cliente Provaccines" />
                        <img src={integramente} alt="Cliente Integramente" />
                        <img src={espacio} alt="Cliente Espacio Interior" />
                        <img src={darfi} alt="Cliente Grupo Darfi" />
                    </div>
                    <div className="opiniones">
                        <div>
                            <div className="foto"></div>
                            <h2>Alfredo Victoria</h2>
                            <h4>ProVaccines</h4>
                            <p>
                                Antes de tener página web la gente no encontraba
                                o reconocía mi consultorio, pero ahora
                                constantemente nos llaman o escriben por
                                messenger. Con eso he podido tener más pacientes
                                y hacer crecer mi empresa.
                            </p>
                            <img
                                className="estrellas"
                                src={estrellas}
                                alt="Calificación 5 estrellas"
                            />
                        </div>
                        <div>
                            <div className="foto"></div>
                            <h2>Jimena Mijares</h2>
                            <h4>Integramente</h4>
                            <p>
                                Me gustó mucho mi página web porque, además de
                                que se ve bien en cualquier teléfono, Headtag
                                creó una plataforma personalizada donde puedo
                                subir fácilmente los artículos que escribo para
                                el blog.
                            </p>
                            <img
                                className="estrellas"
                                src={estrellas}
                                alt="Calificación 5 estrellas"
                            />
                        </div>
                    </div>
                </section>
                <section className="formulario segundo">
                    <form
                        id="diseno-abajo"
                        className="form "
                        onSubmit={this.onSubmit}
                    >
                        <h2>Déjanos tus datos</h2>
                        <label htmlFor="nombre">Nombre y apellido*: </label>
                        <input
                            type="text"
                            name="nombre"
                            value={this.state.values.nombre}
                            onChange={e => this.onChange(e.target, "nombre")}
                            required
                        />
                        <label htmlFor="correo">Correo*:</label>
                        <input
                            type="email"
                            name="correo"
                            value={this.state.values.correo}
                            onChange={e => this.onChange(e.target, "correo")}
                            required
                        />
                        <label htmlFor="telefono">Teléfono*: </label>
                        <input
                            type="tel"
                            name="telefono"
                            value={this.state.values.telefono}
                            onChange={e => this.onChange(e.target, "telefono")}
                            required
                            pattern="[0-9]{10}"
                        />
                        <label htmlFor="servicio">
                            Servicio que te interesa:{" "}
                        </label>
                        <select
                            name="servicio"
                            id="servicio"
                            value={this.state.values.servicio}
                            onChange={e => this.onChange(e.target, "servicio")}
                            required
                        >
                            <option value="Diseño Web">Diseño Web</option>
                            <option value="SEO / SEM">SEO / SEM</option>
                            <option value="Diseño Gráfico">
                                Diseño Gráfico
                            </option>
                            <option value="Community Management">
                                Community Management
                            </option>
                            <option value="Ilustración">Ilustración</option>
                        </select>
                        <label htmlFor="comentarios">
                            Comentarios (opcional):{" "}
                        </label>
                        <textarea
                            name="comentarios"
                            id="comentarios"
                            value={this.state.values.comentarios}
                            onChange={e =>
                                this.onChange(e.target, "comentarios")
                            }
                        ></textarea>
                        <div className="boton-enviar">
                            <button type="submit">Enviar</button>
                        </div>
                    </form>
                    <div>
                        <img src={arreglamos} alt="" />
                    </div>
                </section>
                <footer>
                    <p className="conoce">
                        Si quieres conocer más acerca de Headtag y nuestro
                        trabajo,
                        <br />
                        <b>¡visita nuestra página web y redes sociales!</b>
                    </p>
                    <p>
                        <a href="/">www.headtag.mx</a>
                    </p>

                    <b className="at">@headtagmx</b>
                    <div className="redes">
                        <Redes />
                    </div>
                    <p className="marca-registrada">
                        Copyright 2020. Headtag es una marca registrada.
                    </p>
                </footer>
            </main>
        );
    }

    onSubmit = e => {
        e.preventDefault();
        const {
            nombre,
            comentarios,
            correo,
            telefono,
            servicio
        } = this.state.values;
        fetch(
            `https://headtag.info/php/mensajes?nombre=${nombre}&comentarios=${comentarios}&correo=${correo}&telefono=${telefono}&servicio=${servicio}`
        ).then(() => {
            this.setState({ formEnviada: true });
        });
    };

    onChange = (target, campo) => {
        this.setState({
            values: {
                ...this.state.values,
                [campo]: target.value
            }
        });
    };
}
