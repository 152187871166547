import { IRutas } from "../types/RouteTypes";

export const routes: IRutas = {
    Hectorol: {
        title: "Hectorol | Headtag",
        canonical: "https://headtag.mx/hectorol",
        description: "Página de Hectorol",
        link: "/hectorol",
        name: "Hectorol",
        microservicios: []
    },
    Inicio: {
        title:
            "Headtag | Agencia de diseño | Posicionamiento web | Apps móviles",
        canonical: "https://headtag.mx/",
        description:
            "Hacemos que tu marca y presencia online se vea bien (diseño gráfico), funcione correctamente (desarrollo web) y la encuentren en internet (SEO). ¡Visítanos! ",
        link: "/",
        name: "Inicio",
        microservicios: []
    },
    IniciaTuProyecto: {
        title: "Inicia tu proyecto | Headtag",
        canonical: "https://headtag.mx/inicia-tu-proyecto",
        description: "Inicia tu proyecto de Headtag",
        link: "/inicia-tu-proyecto",
        name: "Inicia tu proyecto",
        microservicios: []
    },
    Servicios: {
        title: "Servicios | Headtag",
        canonical: "https://headtag.mx/#servicios",
        description: "Servicios de Headtag",
        link: "/#servicios",
        name: "Servicios",
        microservicios: []
    },
    Logros: {
        title: "Logros | Headtag",
        canonical: "https://headtag.mx/logros",
        description:
            "Descubre cómo logramos hacer las empresas de nuestros clientes mediante nuestro servicios de diseño gráfico, desarrollo web, marketing digital. ¡Visítanos!",
        link: "/logros",
        name: "Logros",
        microservicios: []
    },
    Cultura: {
        title: "Cultura | Headtag",
        canonical: "https://headtag.mx/cultura",
        description:
            "Descubre qué es Headtag, quiénes forman parte de este proyecto y cuáles son las metas que queremos lograr con cada uno de nuestros clientes y servicios.",
        link: "/cultura",
        name: "Cultura",
        microservicios: []
    },
    Blog: {
        title: "Blog | Headtag",
        canonical: "https://headtag.mx/blog",
        description: "Página principal de Headtag",
        link: "/blog",
        name: "Blog",
        microservicios: []
    },
    "landing-diseno": {
        title: " Agencia de diseño gráfico | Agencia de diseño | Headtag",
        canonical: "https://headtag.mx/landing/diseno",
        description:
            "Somos una agencia de diseño gráfico. Haremos de tu marca una experiencia memorable. Identidad corporativa, branding y diseño web. ¡Contáctanos!",
        link: "/landing/diseno",
        name: "Landing Page Diseño",
        microservicios: []
    },
    "seo-sem": {
        title:
            "SEO en México | SEO en Puebla | Agencia de Google Ads | Headtag",
        canonical: "https://headtag.mx/servicios/SEO-SEM",
        description:
            "Con nuestra estrategia de SEO en México tu negocio aparecerá en la primera página de Google y así podrás aumentar tus ventas. ¡Conócenos!",
        link: "/servicios/seo-sem",
        name: "SEO / SEM",
        showedImage: "SEO.svg",
        logo: "seo.svg",
        subtitulo: "Tu empresa en la primera página de Google",
        microservicios: [
            ["Google Ads", "google-ads.svg"],
            ["Blog", "BLOG.svg"],
            ["Google Analytics", "ANALYTICS.svg"]
        ],
        imagenMicroservicios: "seo.jpg",
        tituloMicroservicios: "SEO",
        textoMicroservicios:
            "Con nuestra estrategia, tu sitio web aparecerá en la primera página de Google cuando la gente busque tus productos o servicios, con lo que aumentarás tus ventas."
    },
    "desarrollo-web": {
        title:
            "Agencia de desarrollo web | Desarrollo de aplicaciones móviles | Headtag",
        canonical: "https://headtag.mx/servicios/desarrollo-web",
        description:
            "Somos una agencia de desarrollo web, especializada páginas web, sistemas y aplicaciones móviles. iOS, Android y aplicaciones web. ¡Escríbenos!",
        link: "/servicios/desarrollo-web",
        name: "Desarrollo Web",
        showedImage: "DESARROLLOWEB.svg",
        logo: "web.svg",
        subtitulo: "Mejorando tu empresa con la ayuda de la tecnología",
        microservicios: [
            ["Sitio Web", "sitio-web.svg"],
            ["Tienda en línea", "tienda-en-linea.svg"],
            ["Bases de datos", "bases-de-datos.svg"],
            ["Apps", "APPS.svg"],
            ["Dominio y hosting", "dominio-hosting.svg"]
        ],
        imagenMicroservicios: "interior.jpg",
        tituloMicroservicios: "Desarrollo Web",
        textoMicroservicios:
            "Mediante la creación de páginas web, aplicaciones y sistemas, hacemos que la navegación, accesibilidad, usabilidad y administración de los recursos de tu empresa sean más fáciles para tu equipo, clientes y prospectos."
    },
    "diseno-grafico": {
        title: "Diseño Gráfico | Headtag",
        canonical: "https://headtag.mx/servicios/diseno-grafico",
        description:
            "A través del desarrollo de la identidad corporativa, branding y  \npapelería para tu empresa, creamos una marca memorable \nque llame la atención de tus posibles clientes, \ncuidando la personalidad que quieras transmitir.",
        link: "/servicios/diseno-grafico",
        name: "Diseño Gráfico",
        showedImage: "DISENOGRAFICO.svg",
        logo: "diseno.svg",
        subtitulo: "Creando una identidad para tu empresa",
        microservicios: [
            ["Branding", "BRANDING.svg"],
            ["Naming", "NAMING.svg"],
            ["Papelería", "PAPELERIA.svg"]
        ],
        imagenMicroservicios: "membrete.jpg",
        tituloMicroservicios: "Diseño Gráfico",
        textoMicroservicios:
            "A través del desarrollo de la identidad corporativa, branding y papelería para tu empresa, creamos una marca memorable que llame la atención de tus posibles clientes, cuidando la personalidad que quieras transmitir."
    },
    "community-management": {
        title:
            "Agencia de redes sociales | Manejo de redes sociales México | Headtag",
        canonical: "https://headtag.mx/servicios/community-management",
        description:
            "Somos una agencia de redes sociales. Manejamos tus redes sociales para generar una comunidad y aumentar tus ventas. ¡Contáctanos!",
        link: "/servicios/community-management",
        name: "Community Management",
        showedImage: "MARKETING.svg",
        logo: "pantalla.svg",
        subtitulo: "Tu empresa en las redes sociales",
        microservicios: [
            ["Posts para redes sociales", "POSTS.svg"],
            // ["Community Management", "COMMUNITY.svg"],
            ["Campañas Digitales", "campanas.svg"],
            ["Facebook Ads", "facebook-ads.svg"],
            ["Landing Pages", "LANDING.svg"]
        ],
        imagenMicroservicios: "mock.jpg",
        tituloMicroservicios: "Community Management",
        textoMicroservicios:
            "Por medio de la creación y promoción de contenido en las principales plataformas  digitales (Facebook, Instagram, YouTube), logramos que tu cliente ideal reconozca tu marca."
    }
};
