import React, { Component } from "react";
import fallback from "../images/fallback.svg";

export default class Fallback extends Component {
    state = {
        show: true
    };

    render() {
        if (this.props.timeout && this.state.show) {
            setTimeout(
                () => this.setState({ show: false }),
                this.props.timeout
            );
        }
        if (this.state.show) {
            return (
                <div
                    style={{
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        zIndex: 9999999,
                        backgroundColor: "white",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "fixed"
                    }}
                >
                    <img
                        src={fallback}
                        style={{ height: "40vh", marginTop: "auto" }}
                        alt="Cohete Headtag"
                    />
                    <h1 style={{ margin: "0 auto auto", textAlign: "center" }}>
                        Cargando algo <br /> grandioso...
                    </h1>
                </div>
            );
        } else {
            return null;
        }
    }
}
