import React, { Component, Fragment } from "react";
import "../css/entrada-blog.scss";
import { Footer } from "./Footer";
import { NotFound } from "./NotFound";
import Helmet from "react-helmet";
import { routes } from "../routes/Routes";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";

export class EntradaBlog extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { entradas: {} };
    }
    componentDidMount() {
        fetch(
            `https://headtag.info/php/entrada.php?url=${this.props.match.params.entrada}`
        )
            .then(data => data.json())
            .then(entradas =>
                this.setState(entradas ? { entradas } : { entradas: null })
            )
            .catch(error => console.log(error));
    }
    render() {
        if (this.state.entradas === null) {
            return <NotFound />;
        }
        if (
            typeof this.state.entradas.isBlog === "string" &&
            !Number(this.state.entradas.isBlog) &&
            this.props.match.path === "/blog/:entrada"
        ) {
            return <Redirect to={`/${this.state.entradas.url}`} />;
        } else if (
            typeof this.state.entradas.isBlog === "string" &&
            !!Number(this.state.entradas.isBlog) &&
            this.props.match.path === "/:entrada"
        ) {
            return <Redirect to={`/blog/${this.state.entradas.url}`} />;
        }
        const content =
            this.state.entradas.texto &&
            this.state.entradas.texto
                .replace(new RegExp(/blog\//gm), "https://headtag.info/blog/")
                .replace(
                    new RegExp(/images\//gm),
                    "https://headtag.info/images/"
                )
                .replace(new RegExp(/\/https/gm), "https");
        return (
            <Fragment>
                <Helmet>
                    <title>
                        {this.state.entradas.titulo
                            ? `${this.state.entradas.titulo} | ${routes.Blog.title}`
                            : "Cargando... | Headtag"}
                    </title>
                    <link
                        rel="canonical"
                        href={`${routes.Inicio.canonical}${
                            this.state.entradas.isBlog ? "blog/" : ""
                        }${this.state.entradas.url}`}
                    />
                    <meta
                        name="description"
                        content={this.state.entradas.og_description}
                    />
                    <meta name="robots" content="index,follow" />
                    <meta name="googlebot" content="index,follow" />
                    <meta name="revisit-after" content="7 days" />
                    <meta name="rating" content="General" />
                    <meta name="location" content="México" />
                    <meta
                        name="classification"
                        content="Agencia de diseño | Posicionamiento web | Apps móviles"
                    />
                    <meta
                        property="og:description"
                        content={this.state.entradas.og_description}
                    />
                    <meta
                        property="og:title"
                        content={this.state.entradas.titulo}
                    />
                    <meta
                        property="og:url"
                        content={`${routes.Inicio.canonical}blog/${this.state.entradas.url}`}
                    />
                    <meta
                        property="og:image"
                        content={`https://headtag.info/blog/og/${this.state.entradas.og_image}`}
                    />
                </Helmet>
                <section id={"blog-entry"}>
                    <div
                        className="cover"
                        style={
                            this.state.entradas.portada && {
                                backgroundImage: `url("https://headtag.info/blog/portada/${this.state.entradas.portada}")`
                            }
                        }
                    >
                        <div className="blog-entry-data">
                            <div>
                                <h1>
                                    {this.state.entradas.titulo ? (
                                        this.state.entradas.titulo
                                    ) : (
                                        <Skeleton width={205} />
                                    )}
                                </h1>
                                <h2>
                                    {this.state.entradas.subtitulo ? (
                                        this.state.entradas.subtitulo
                                    ) : (
                                        <Skeleton width={205} />
                                    )}
                                </h2>
                                <p>
                                    {this.state.entradas.autor ? (
                                        `Autor: ${this.state.entradas.autor}`
                                    ) : (
                                        <Skeleton width={105} />
                                    )}
                                </p>
                                <p>
                                    {this.state.entradas.fecha ? (
                                        `Fecha: ${this.state.entradas.fecha}`
                                    ) : (
                                        <Skeleton width={105} />
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    {content ? (
                        <div
                            className={"blog-entry-body"}
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    ) : (
                        <div
                            className={"blog-entry-body"}
                            style={{ lineHeight: "20px" }}
                        >
                            <Skeleton count={105} />
                        </div>
                    )}
                </section>
                <Footer />
            </Fragment>
        );
    }
}
