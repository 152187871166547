import React from "react";

import { Desktop } from "./Responsive";

import logohorizontal from "../images/logo_horizontal.svg";
import cafe from "../images/cafe.svg";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import whatsapp from "../images/whatsapp.svg";
import behance from "../images/behance.svg";
import linkedin from "../images/linkedin.svg";
import youtube from "../images/youtube.svg";
import twitter from "../images/twitter.svg";
import spotify from "../images/spotify.svg";

import "../css/footer.scss";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
    return (
        <section id="contacto-footer">
            <div id={"cafe"}>
                <div>
                    <div>
                        <h2>¡Te invitamos un café!</h2>
                        <br />
                        <p>Contáctanos</p>
                        <br />
                        <a href="mailto: hola@headtag.mx">hola@headtag.mx</a>
                        <br />
                        <br />
                        <a href="tel: +52 1 222 306 09 00">
                            +52 1 222 306 09 00
                        </a>
                    </div>
                    <img src={cafe} alt="Tasa de café" />
                </div>
            </div>
            <footer>
                <div id={"footer-site-map"}>
                    <Desktop>
                        <img src={logohorizontal} alt="" />
                    </Desktop>
                    <div>
                        <h2>Servicios</h2>
                        <a href="/servicios/desarrollo-web">Desarrollo Web</a>
                        <a href="/servicios/seo-sem">SEO / SEM</a>
                        <a href="/servicios/diseno-grafico">Diseño Gráfico</a>
                        <a href="/servicios/community-management">
                            Community Management
                        </a>
                        <a href="/hectorol">Ilustración</a>
                        <a href="https://www.marketingparamedicos.mx/">
                            marketing medico
                        </a>
                        <a href="https://www.marketingparamedicos.mx/blog/publicidad-para-medicos">
                            publicidad para medicos
                        </a>
                        <a href="https://www.marketingparamedicos.mx/blog/ginecologos-en-puebla">
                            ginecologos en puebla
                        </a>
                        <a href="https://www.marketingparamedicos.mx/blog/dermatologos-en-puebla">
                            dermatologos en puebla
                        </a>
                        <a href="https://www.marketingparamedicos.mx/paginas-web-puebla">
                            paginas web puebla
                        </a>
                    </div>
                    <div>
                        <h2>Logros</h2>
                        <HashLink to="/#clientes">Nuestros Clientes</HashLink>
                        <a href="/logros">Casos de éxito</a>
                    </div>
                    <div>
                        <h2>Cultura</h2>
                        <a href="/cultura">Nosotros</a>
                        <a href="/cultura">Misión</a>
                        <a href="/cultura">Visión</a>
                    </div>
                    <div>
                        <h2>Blog</h2>
                        <a href="/blog">Artículos de interés</a>
                    </div>
                    <div className={"social"}>
                        <a href="https://www.facebook.com/headtagmx/">
                            <img src={facebook} alt="facebook" />
                        </a>
                        <a href="https://www.instagram.com/headtagmx/">
                            <img src={instagram} alt="instagram" />
                        </a>
                        <a href="https://www.behance.net/headtagmx">
                            <img src={behance} alt="behance" />
                        </a>
                        <a href="https://www.linkedin.com/company/headtagmx/">
                            <img src={linkedin} alt="linkedin" />
                        </a>
                        <a href="https://www.twitter.com/headtagmx">
                            <img src={twitter} alt="twitter" />
                        </a>
                        <a href="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA">
                            <img src={youtube} alt="youtube" />
                        </a>
                        <a href="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw">
                            <img src={spotify} alt="spotify" />
                        </a>
                        <a href="https://web.whatsapp.com/send?phone=522223060900&text=&source=&data=%23">
                            <img src={whatsapp} alt="whatsapp" />
                        </a>
                    </div>
                </div>
                <hr />
                <div className={"copyright"}>
                    <p>Copyright 2020. Headtag es una marca registrada.</p>
                </div>
            </footer>
        </section>
    );
};
