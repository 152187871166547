import React from "react";
import FourOFour from "../images/404.svg";
import "../css/not-found.scss";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Footer } from "./Footer";

export const NotFound = () => {
    return (
        <>
            <section id="fourofour">
                <Helmet>
                    <title>Not found | Headtag</title>
                    <meta name="description" content="Not found" />
                    <meta name="robots" content="index,follow" />
                    <meta name="googlebot" content="index,follow" />
                    <meta name="revisit-after" content="7 days" />
                    <meta name="rating" content="General" />
                    <meta name="location" content="México" />
                    <meta
                        name="classification"
                        content="Agencia de diseño | Posicionamiento web | Apps móviles"
                    />
                </Helmet>

                <img src={FourOFour} alt="¿Estás perdido?" />
                <h2>Error 404</h2>
                <h1>
                    Nos han robado esta página ¿quieres regresar al
                    <Link to="/"> inicio</Link>?
                </h1>
            </section>
            <Footer />
        </>
    );
};
