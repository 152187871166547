import React from "react";

import "../css/redes.scss";

const Redes = props => {
    if (props.doctores) {
        return (
            <div>
                <div className="mobile">
                    <svg
                        id="CinturonRedes"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 438.66 177.74"
                        className="cinturon-redes"
                    >
                        <title></title>
                        <a
                            xlinkHref="https://www.headtag.mx"
                            href="https://www.headtag.mx"
                        >
                            <g className="red">
                                <path d="M37.24,0A37.24,37.24,0,1,0,74.48,37.24,37.24,37.24,0,0,0,37.24,0Zm23,44.08H56.49l-1.21-3.46-.47-1.76-.45,1.72-1.22,3.5H49.39L45.84,33.89,42.3,44.08H38.55l-1.2-3.46-.47-1.76-.46,1.72-1.22,3.5H31.45L27.9,33.89,24.37,44.08H20.62l-1.21-3.46-.47-1.76-.46,1.72-1.22,3.5H13.51l-4-11.61h4l2,6.67,1.93-6.67h3l1.94,6.68,2-6.68h7l2,6.67,1.93-6.67h3l1.94,6.68,2-6.68h7l2,6.67,1.92-6.67h3l2,6.68,2-6.68h4Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.facebook.com/headtagmx/"
                            href="https://www.facebook.com/headtagmx/"
                        >
                            <g className="red">
                                <path d="M127.24,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,127.24,0ZM137.8,23.85c-1.33,0-2.56,0-3.79,0-2.68.08-3.47.89-3.51,3.63V31.7h7.12c-.32,2.66-.59,5-.93,7.57h-6.22V57.7H123V39.4h-6.29V31.77h6.08c.37-4.84-1-10,3.81-13.28,3.44-2.29,7.27-1.82,11.23-1.28Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.instagram.com/headtagmx/"
                            href="https://www.instagram.com/headtagmx/"
                        >
                            <g className="red">
                                <path d="M218.08,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,218.08,0Zm17.86,44.65a10.59,10.59,0,0,1-6.15,9.5,9.47,9.47,0,0,1-2.93.85,11,11,0,0,1-1.52.1H210.68a10.26,10.26,0,0,1-6.13-2,10.64,10.64,0,0,1-3.3-3.93,9.71,9.71,0,0,1-1-3.2,13.27,13.27,0,0,1-.1-1.54V29.82a10.22,10.22,0,0,1,1.09-4.54,10.59,10.59,0,0,1,7.44-5.71,11.54,11.54,0,0,1,2-.2h14.79a10.19,10.19,0,0,1,4.2,1,10.58,10.58,0,0,1,5.91,7.34,10.16,10.16,0,0,1,.24,2.2Q236,37.26,235.94,44.65Z" />
                                <path d="M228.53,23.28a2.15,2.15,0,1,0,.05,0Z" />
                                <path d="M217.88,26a11.28,11.28,0,1,0,11.47,11.08c0-.21,0-.41,0-.62A11.3,11.3,0,0,0,217.88,26Zm.22,18.6a7.31,7.31,0,1,1,7.29-7.33,7.31,7.31,0,0,1-7.29,7.33Z" />
                            </g>
                        </a>
                        <a xlinkHref="Whatsapp" href="Whatsapp">
                            <g className="red">
                                <path d="M319.78,42.06c-.72-.42-1.36-.8-2.19-1.21a2.35,2.35,0,0,0-3.78.53c-.54.59-1.08,1.13-1.62,1.68,0,0-6.95-2.54-8.8-7.73l.81-.93c.45-.53,1.13-1,1.17-1.79a5.29,5.29,0,0,0-.48-2.11c-.17-.43-.34-.85-.5-1.28a8.61,8.61,0,0,0-1.1-2.31,2.07,2.07,0,0,0-2.35-.37s-4.56,2.2-3.16,6.6A20.7,20.7,0,0,0,314.2,48.08s4.92.7,6.24-2.29a5.13,5.13,0,0,0,.43-2.25A1.85,1.85,0,0,0,319.78,42.06Z" />
                                <path d="M309.54,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,309.54,0Zm0,56.58a19.22,19.22,0,0,1-11-3.41l-6.65,1.92,1.7-6.85a19.36,19.36,0,1,1,15.9,8.34Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.behance.net/headtagmx"
                            href="https://www.behance.net/headtagmx"
                        >
                            <g className="red">
                                <path d="M401.41,0a37.24,37.24,0,1,0,37.25,37.24A37.24,37.24,0,0,0,401.41,0Zm5.87,26.31h9.59V28.7h-9.61Zm-6.55,19.43a6.46,6.46,0,0,1-4.37,3,16.49,16.49,0,0,1-3.36.32H382.27V25.32h11.5c2.94.05,5,.89,6.18,2.54A6.08,6.08,0,0,1,401,31.5,5.3,5.3,0,0,1,399.94,35a5.5,5.5,0,0,1-1.82,1.36,5.28,5.28,0,0,1,2.75,2.1A6.33,6.33,0,0,1,401.8,42,7,7,0,0,1,400.73,45.74Zm19.86-4.61H408.16a4.11,4.11,0,0,0,1.76,3.61,4.53,4.53,0,0,0,2.46.64,3.49,3.49,0,0,0,3.39-2h4.55a5.49,5.49,0,0,1-1.65,3.08,8.32,8.32,0,0,1-6.42,2.5,9.31,9.31,0,0,1-6-2.06q-2.6-2.12-2.6-6.84,0-4.44,2.34-6.8a8.2,8.2,0,0,1,6.11-2.35,9.76,9.76,0,0,1,4,.74A6.9,6.9,0,0,1,419,34.2a8.38,8.38,0,0,1,1.36,3.52A22.86,22.86,0,0,1,420.59,41.13Z" />
                                <path d="M394.32,34.13a2.24,2.24,0,0,0,1-2.1A2.14,2.14,0,0,0,394,29.84a9.21,9.21,0,0,0-2.81-.38h-4.45v5.28h5A4.75,4.75,0,0,0,394.32,34.13Z" />
                                <path d="M411.09,34.6a3.53,3.53,0,0,0-2.64.95,4.65,4.65,0,0,0-1.17,2.64H415a3.73,3.73,0,0,0-1.17-2.7A3.88,3.88,0,0,0,411.09,34.6Z" />
                                <path d="M394.26,39.07a6.06,6.06,0,0,0-2.38-.4h-5.13V45h5a5.68,5.68,0,0,0,2.41-.42,3,3,0,0,0,1.56-3A2.55,2.55,0,0,0,394.26,39.07Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.linkedin.com/company/headtagmx/"
                            href="https://www.linkedin.com/company/headtagmx/"
                        >
                            <g className="red">
                                <path d="M81.49,103a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,81.49,103Zm-10,54.71H63.69V134.14h7.83ZM67.6,130.92h0a4.1,4.1,0,1,1,4.49-4.1c0,2.31-1.72,4.1-4.45,4.1Zm32.17,26.79H91.93V145.1c0-3.17-1.13-5.33-4-5.33a4.28,4.28,0,0,0-4,2.86,5.44,5.44,0,0,0-.26,1.92v13.16H75.84s.11-21.36,0-23.57h7.84v3.34a7.79,7.79,0,0,1,7-3.9c5.16,0,9,3.37,9,10.61Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
                            href="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
                        >
                            <g className="red">
                                <path d="M169,147.14l11.31-6.89L169,133.37Zm6.47-9.68v.08h-.05S175.44,137.48,175.44,137.46Z" />
                                <path d="M173.23,102.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,173.23,102.74Zm22.68,44.5a9.1,9.1,0,0,1-7.72,8.14,127.83,127.83,0,0,1-15.8.78c-3.95,0-8.72-.23-13.49-.67a9.14,9.14,0,0,1-8.37-8.61,101,101,0,0,1,0-13.7,9.11,9.11,0,0,1,7.71-8.09,116.19,116.19,0,0,1,16.26-.75c4.34.11,8.69.35,13,.67a9,9,0,0,1,8.37,8.59A101.78,101.78,0,0,1,195.91,147.24Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.twitter.com/headtagmx"
                            href="https://www.twitter.com/headtagmx"
                        >
                            <g className="red">
                                <path d="M265.23,102.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,265.23,102.74Zm20.46,25.58c-1,1-2,1.94-3,2.89a.71.71,0,0,0-.29.63,25.55,25.55,0,0,1-6,17.18,23.34,23.34,0,0,1-14.68,8.36,25.19,25.19,0,0,1-16.4-2.7c-.42-.22-.82-.48-1.23-.72l-.25-.18a18.67,18.67,0,0,0,6.86-.56,17.88,17.88,0,0,0,6.12-3.1,9,9,0,0,1-5.1-1.77,8.86,8.86,0,0,1-3.13-4.36,9.06,9.06,0,0,0,3.91-.17,8.88,8.88,0,0,1-5.68-4,8.6,8.6,0,0,1-1.32-4.74,9.38,9.38,0,0,0,3.94,1.06,8.83,8.83,0,0,1-3.81-6.38,8.52,8.52,0,0,1,1.14-5.36,25.2,25.2,0,0,0,18.17,9.21c-.05-.52-.13-1-.15-1.48a8.63,8.63,0,0,1,6-8.89,8.49,8.49,0,0,1,8.79,1.87,1,1,0,0,0,1.06.29,18.67,18.67,0,0,0,4.57-1.77l.43-.21a9.25,9.25,0,0,1-3.64,4.77,18.65,18.65,0,0,0,4.76-1.18A10.55,10.55,0,0,1,285.69,128.32Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
                            href="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
                        >
                            <g className="red">
                                <path d="M356.91,102.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,356.91,102.74Zm-.48,58.1a20.61,20.61,0,1,1,21-20.53A20.64,20.64,0,0,1,356.43,160.84Z" />
                                <path d="M370.82,134.5a39.39,39.39,0,0,0-18.21-4,37.36,37.36,0,0,0-8.16,1.11,2.07,2.07,0,0,0-1.51,2.49,1.86,1.86,0,0,0,2.32,1.42l.88-.19a34.82,34.82,0,0,1,23,2.8,1.63,1.63,0,0,0,1.61.08,2.13,2.13,0,0,0,1.22-1.92A1.87,1.87,0,0,0,370.82,134.5Z" />
                                <path d="M365.59,149.45a25.18,25.18,0,0,0-11.86-3h0a25.78,25.78,0,0,0-7.2,1,2.25,2.25,0,0,0-1.06,3.08,1.37,1.37,0,0,0,1.42.57,5.56,5.56,0,0,0,.7-.19,22.21,22.21,0,0,1,9.73-.55,22.92,22.92,0,0,1,7.1,2.44,1.43,1.43,0,0,0,2-.47A2.33,2.33,0,0,0,365.59,149.45Z" />
                                <path d="M368.09,142.23a32.05,32.05,0,0,0-14.5-3.49,33,33,0,0,0-7.91,1,2.24,2.24,0,0,0-1.56,2.69,1.63,1.63,0,0,0,2,1.25l.58-.14a28.31,28.31,0,0,1,12.52-.36,27.69,27.69,0,0,1,7.39,2.56c1,.5,1.42.39,2.07-.53.08-.12.16-.23.23-.35A1.69,1.69,0,0,0,368.09,142.23Z" />
                            </g>
                        </a>
                    </svg>
                </div>
                <div className="desktop">
                    <svg
                        id="CinturonRedes"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 800.41 77.74"
                        className="cinturon-redes"
                    >
                        <title></title>
                        <a
                            xlinkHref="https://www.headtag.mx"
                            href="https://www.headtag.mx"
                        >
                            <g className="red">
                                <path d="M37.24,0A37.24,37.24,0,1,0,74.48,37.24,37.24,37.24,0,0,0,37.24,0Zm23,44.08H56.49l-1.21-3.46-.47-1.76-.45,1.72-1.22,3.5H49.39L45.84,33.89,42.3,44.08H38.55l-1.2-3.46-.47-1.76-.46,1.72-1.22,3.5H31.45L27.9,33.89,24.37,44.08H20.62l-1.21-3.46-.47-1.76-.46,1.72-1.22,3.5H13.51l-4-11.61h4l2,6.67,1.93-6.67h3l1.94,6.68,2-6.68h7l2,6.67,1.93-6.67h3l1.94,6.68,2-6.68h7l2,6.67,1.92-6.67h3l2,6.68,2-6.68h4Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.facebook.com/headtagmx/"
                            href="https://www.facebook.com/headtagmx/"
                        >
                            <g className="red">
                                <path d="M127.24,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,127.24,0ZM137.8,23.85c-1.33,0-2.56,0-3.79,0-2.68.08-3.47.89-3.51,3.63V31.7h7.12c-.32,2.66-.59,5-.93,7.57h-6.22V57.7H123V39.4h-6.29V31.77h6.08c.37-4.84-1-10,3.81-13.28,3.44-2.29,7.27-1.82,11.23-1.28Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.instagram.com/headtagmx/"
                            href="https://www.instagram.com/headtagmx/"
                        >
                            <g className="red">
                                <path d="M218.08,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,218.08,0Zm17.86,44.65a10.59,10.59,0,0,1-6.15,9.5,9.47,9.47,0,0,1-2.93.85,11,11,0,0,1-1.52.1H210.68a10.26,10.26,0,0,1-6.13-2,10.64,10.64,0,0,1-3.3-3.93,9.71,9.71,0,0,1-1-3.2,13.27,13.27,0,0,1-.1-1.54V29.82a10.22,10.22,0,0,1,1.09-4.54,10.59,10.59,0,0,1,7.44-5.71,11.54,11.54,0,0,1,2-.2h14.79a10.19,10.19,0,0,1,4.2,1,10.58,10.58,0,0,1,5.91,7.34,10.16,10.16,0,0,1,.24,2.2Q236,37.26,235.94,44.65Z" />
                                <path d="M228.53,23.28a2.15,2.15,0,1,0,.05,0Z" />
                                <path d="M217.88,26a11.28,11.28,0,1,0,11.47,11.08c0-.21,0-.41,0-.62A11.3,11.3,0,0,0,217.88,26Zm.22,18.6a7.31,7.31,0,1,1,7.29-7.33,7.31,7.31,0,0,1-7.29,7.33Z" />
                            </g>
                        </a>
                        <a xlinkHref="Whatsapp" href="Whatsapp">
                            <g className="red">
                                <path d="M319.78,42.06c-.72-.42-1.36-.8-2.19-1.21a2.35,2.35,0,0,0-3.78.53c-.54.59-1.08,1.13-1.62,1.68,0,0-6.95-2.54-8.8-7.73l.81-.93c.45-.53,1.13-1,1.17-1.79a5.29,5.29,0,0,0-.48-2.11c-.17-.43-.34-.85-.5-1.28a8.61,8.61,0,0,0-1.1-2.31,2.07,2.07,0,0,0-2.35-.37s-4.56,2.2-3.16,6.6A20.7,20.7,0,0,0,314.2,48.08s4.92.7,6.24-2.29a5.13,5.13,0,0,0,.43-2.25A1.85,1.85,0,0,0,319.78,42.06Z" />
                                <path d="M309.54,0a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,309.54,0Zm0,56.58a19.22,19.22,0,0,1-11-3.41l-6.65,1.92,1.7-6.85a19.36,19.36,0,1,1,15.9,8.34Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.behance.net/headtagmx"
                            href="https://www.behance.net/headtagmx"
                        >
                            <g className="red">
                                <path d="M401.41,0a37.24,37.24,0,1,0,37.25,37.24A37.24,37.24,0,0,0,401.41,0Zm5.87,26.31h9.59V28.7h-9.61Zm-6.55,19.43a6.46,6.46,0,0,1-4.37,3,16.49,16.49,0,0,1-3.36.32H382.27V25.32h11.5c2.94.05,5,.89,6.18,2.54A6.08,6.08,0,0,1,401,31.5,5.3,5.3,0,0,1,399.94,35a5.5,5.5,0,0,1-1.82,1.36,5.28,5.28,0,0,1,2.75,2.1A6.33,6.33,0,0,1,401.8,42,7,7,0,0,1,400.73,45.74Zm19.86-4.61H408.16a4.11,4.11,0,0,0,1.76,3.61,4.53,4.53,0,0,0,2.46.64,3.49,3.49,0,0,0,3.39-2h4.55a5.49,5.49,0,0,1-1.65,3.08,8.32,8.32,0,0,1-6.42,2.5,9.31,9.31,0,0,1-6-2.06q-2.6-2.12-2.6-6.84,0-4.44,2.34-6.8a8.2,8.2,0,0,1,6.11-2.35,9.76,9.76,0,0,1,4,.74A6.9,6.9,0,0,1,419,34.2a8.38,8.38,0,0,1,1.36,3.52A22.86,22.86,0,0,1,420.59,41.13Z" />
                                <path d="M394.32,34.13a2.24,2.24,0,0,0,1-2.1A2.14,2.14,0,0,0,394,29.84a9.21,9.21,0,0,0-2.81-.38h-4.45v5.28h5A4.75,4.75,0,0,0,394.32,34.13Z" />
                                <path d="M411.09,34.6a3.53,3.53,0,0,0-2.64.95,4.65,4.65,0,0,0-1.17,2.64H415a3.73,3.73,0,0,0-1.17-2.7A3.88,3.88,0,0,0,411.09,34.6Z" />
                                <path d="M394.26,39.07a6.06,6.06,0,0,0-2.38-.4h-5.13V45h5a5.68,5.68,0,0,0,2.41-.42,3,3,0,0,0,1.56-3A2.55,2.55,0,0,0,394.26,39.07Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.linkedin.com/company/headtagmx/"
                            href="https://www.linkedin.com/company/headtagmx/"
                        >
                            <g className="red">
                                <path d="M487.49,3a37.24,37.24,0,1,0,37.24,37.24A37.24,37.24,0,0,0,487.49,3Zm-10,54.71h-7.83V34.14h7.83ZM473.6,30.92h-.05a4.1,4.1,0,1,1,4.49-4.1c0,2.31-1.72,4.1-4.45,4.1Zm32.17,26.79h-7.84V45.1c0-3.17-1.13-5.33-4-5.33a4.28,4.28,0,0,0-4,2.86,5.44,5.44,0,0,0-.26,1.92V57.71h-7.84s.11-21.36,0-23.57h7.84v3.34a7.79,7.79,0,0,1,7-3.9c5.16,0,9,3.37,9,10.61Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
                            href="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
                        >
                            <g className="red">
                                <path d="M575,47.14l11.31-6.89L575,33.37Zm6.47-9.68v.08h-.05S581.44,37.48,581.44,37.46Z" />
                                <path d="M579.23,2.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,579.23,2.74Zm22.68,44.5a9.1,9.1,0,0,1-7.72,8.14,127.83,127.83,0,0,1-15.8.78c-3.95,0-8.72-.23-13.49-.67a9.14,9.14,0,0,1-8.37-8.61,101,101,0,0,1,0-13.7,9.11,9.11,0,0,1,7.71-8.09,116.19,116.19,0,0,1,16.26-.75c4.34.11,8.69.35,13,.67a9,9,0,0,1,8.37,8.59A101.78,101.78,0,0,1,601.91,47.24Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://www.twitter.com/headtagmx"
                            href="https://www.twitter.com/headtagmx"
                        >
                            <g className="red">
                                <path d="M671.23,2.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,671.23,2.74Zm20.46,25.58c-1,1-2,1.94-3,2.89a.71.71,0,0,0-.29.63,25.55,25.55,0,0,1-6,17.18,23.34,23.34,0,0,1-14.68,8.36,25.19,25.19,0,0,1-16.4-2.7c-.42-.22-.82-.48-1.23-.72l-.25-.18a18.67,18.67,0,0,0,6.86-.56,17.88,17.88,0,0,0,6.12-3.1,9,9,0,0,1-5.1-1.77A8.86,8.86,0,0,1,654.53,44a9.06,9.06,0,0,0,3.91-.17,8.88,8.88,0,0,1-5.68-4,8.6,8.6,0,0,1-1.32-4.74,9.38,9.38,0,0,0,3.94,1.06,8.83,8.83,0,0,1-3.81-6.38,8.52,8.52,0,0,1,1.14-5.36,25.2,25.2,0,0,0,18.17,9.21c0-.52-.13-1-.15-1.48a8.63,8.63,0,0,1,6-8.89,8.49,8.49,0,0,1,8.79,1.87,1,1,0,0,0,1.06.29,18.67,18.67,0,0,0,4.57-1.77l.43-.21a9.25,9.25,0,0,1-3.64,4.77A18.65,18.65,0,0,0,692.69,27,10.55,10.55,0,0,1,691.69,28.32Z" />
                            </g>
                        </a>
                        <a
                            xlinkHref="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
                            href="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
                        >
                            <g className="red">
                                <path d="M762.91,2.74a37.5,37.5,0,1,0,37.5,37.5A37.5,37.5,0,0,0,762.91,2.74Zm-.48,58.1a20.61,20.61,0,1,1,21-20.53A20.64,20.64,0,0,1,762.43,60.84Z" />
                                <path d="M776.82,34.5a39.39,39.39,0,0,0-18.21-4,37.36,37.36,0,0,0-8.16,1.11,2.07,2.07,0,0,0-1.51,2.49,1.86,1.86,0,0,0,2.32,1.42l.88-.19a34.82,34.82,0,0,1,23,2.8,1.63,1.63,0,0,0,1.61.08A2.13,2.13,0,0,0,778,36.27,1.87,1.87,0,0,0,776.82,34.5Z" />
                                <path d="M774.09,42.23a32.05,32.05,0,0,0-14.5-3.49,33,33,0,0,0-7.91,1,2.24,2.24,0,0,0-1.56,2.69,1.63,1.63,0,0,0,2,1.25l.58-.14a28.31,28.31,0,0,1,12.52-.36,27.69,27.69,0,0,1,7.39,2.56c1,.5,1.42.39,2.07-.53.08-.12.16-.23.23-.35A1.69,1.69,0,0,0,774.09,42.23Z" />
                                <path d="M771.59,49.45a25.18,25.18,0,0,0-11.86-3h0a25.78,25.78,0,0,0-7.2,1,2.25,2.25,0,0,0-1.06,3.08,1.37,1.37,0,0,0,1.42.57,5.56,5.56,0,0,0,.7-.19,22.21,22.21,0,0,1,9.73-.55,22.92,22.92,0,0,1,7.1,2.44,1.43,1.43,0,0,0,2-.47A2.33,2.33,0,0,0,771.59,49.45Z" />
                            </g>
                        </a>
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <svg
            id="CinturonRedes"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 418 50.19"
            className={`cinturon-redes ${props.size}`}
        >
            <title></title>
            <a
                xlinkHref="https://www.facebook.com/headtagmx/"
                href="https://www.facebook.com/headtagmx/"
            >
                <g className="red">
                    <path d="M24.92.17A24.92,24.92,0,1,0,49.84,25.09,24.92,24.92,0,0,0,24.92.17Zm7.06,16c-.89,0-1.71,0-2.53,0-1.8.05-2.33.6-2.35,2.43v2.83h4.76c-.21,1.78-.39,3.35-.62,5.06H27.08V38.78h-5V26.54h-4.2v-5.1h4.06c.25-3.25-.68-6.73,2.55-8.89,2.3-1.54,4.87-1.22,7.51-.86Z" />
                </g>
            </a>
            <a
                xlinkHref="https://www.instagram.com/headtagmx/"
                href="https://www.instagram.com/headtagmx/"
            >
                <g className="red">
                    <path d="M85.57,17.55A7.55,7.55,0,1,0,93.25,25c0-.14,0-.27,0-.41A7.57,7.57,0,0,0,85.57,17.55ZM85.72,30a4.89,4.89,0,1,1,4.87-4.91A4.9,4.9,0,0,1,85.72,30Z" />
                    <path d="M92.69,15.75a1.48,1.48,0,1,0,1.46,1.54,1.47,1.47,0,0,0-1.46-1.54Z" />
                    <path d="M85.7.17a24.92,24.92,0,1,0,24.92,24.92A24.92,24.92,0,0,0,85.7.17Zm12,29.88a7.08,7.08,0,0,1-4.11,6.36,6.4,6.4,0,0,1-2,.57c-.34,0-.68.06-1,.07H80.75a6.9,6.9,0,0,1-4.1-1.35,7,7,0,0,1-2.21-2.63,6.62,6.62,0,0,1-.64-2.14,8.76,8.76,0,0,1-.07-1V20.13a6.81,6.81,0,0,1,.73-3,7.11,7.11,0,0,1,5-3.82,6.89,6.89,0,0,1,1.36-.13h9.89a6.83,6.83,0,0,1,2.81.64,7.08,7.08,0,0,1,4,4.91,6.56,6.56,0,0,1,.16,1.47Q97.67,25.11,97.65,30.05Z" />
                </g>
            </a>
            <a
                xlinkHref="https://www.behance.net/headtagmx"
                href="https://www.behance.net/headtagmx"
            >
                <g className="red">
                    <path d="M153.3,23.33a2.41,2.41,0,0,0-1.77.63,3.07,3.07,0,0,0-.78,1.77h5.15a2.52,2.52,0,0,0-.79-1.81A2.59,2.59,0,0,0,153.3,23.33Z" />
                    <path d="M142.08,23a1.49,1.49,0,0,0,.65-1.4,1.43,1.43,0,0,0-.86-1.47,6,6,0,0,0-1.88-.25h-3v3.53h3.38A3.23,3.23,0,0,0,142.08,23Z" />
                    <path d="M146.83.17a24.92,24.92,0,1,0,24.91,24.92A24.92,24.92,0,0,0,146.83.17Zm3.92,17.61h6.42v1.59h-6.44Zm-4.38,13a4.36,4.36,0,0,1-2.93,2,11.29,11.29,0,0,1-2.25.22H134V17.12h7.7c2,0,3.32.59,4.13,1.7a4.07,4.07,0,0,1,.73,2.43,3.62,3.62,0,0,1-.73,2.36,3.9,3.9,0,0,1-1.22.91,3.56,3.56,0,0,1,1.84,1.4,4.26,4.26,0,0,1,.62,2.36A4.62,4.62,0,0,1,146.37,30.78Zm13.29-3.08h-8.32a2.72,2.72,0,0,0,1.18,2.41,3,3,0,0,0,1.64.43,2.32,2.32,0,0,0,2.27-1.33h3a3.76,3.76,0,0,1-1.11,2.07,5.53,5.53,0,0,1-4.29,1.66,6.25,6.25,0,0,1-4-1.37q-1.74-1.41-1.74-4.58a6.2,6.2,0,0,1,1.57-4.55A5.48,5.48,0,0,1,154,20.87a6.55,6.55,0,0,1,2.69.49,4.68,4.68,0,0,1,2,1.7,5.55,5.55,0,0,1,.91,2.35A15.91,15.91,0,0,1,159.66,27.7Z" />
                    <path d="M142,26.32a4,4,0,0,0-1.6-.27H137V30.3h3.37A3.9,3.9,0,0,0,142,30a2,2,0,0,0,1-2A1.69,1.69,0,0,0,142,26.32Z" />
                </g>
            </a>
            <a
                xlinkHref="https://www.linkedin.com/company/headtagmx/"
                href="https://www.linkedin.com/company/headtagmx/"
            >
                <g className="red">
                    <path className="cls-1" d="M208.07,23.3v-.06l0,.06Z" />
                    <path d="M208.62.17a24.92,24.92,0,1,0,24.91,24.92A24.92,24.92,0,0,0,208.62.17Zm-6.68,36.61h-5.23V21H202Zm-2.62-17.92h0a2.74,2.74,0,1,1,3-2.75,2.73,2.73,0,0,1-3,2.75Zm21.53,17.92H215.6V28.34c0-2.12-.76-3.57-2.65-3.57a2.88,2.88,0,0,0-2.69,1.92,3.51,3.51,0,0,0-.18,1.28v8.81h-5.24s.07-14.29,0-15.77h5.24v2.23a5.22,5.22,0,0,1,4.71-2.6c3.45,0,6,2.25,6,7.1Z" />
                </g>
            </a>
            <a
                xlinkHref="https://www.twitter.com/headtagmx"
                href="https://www.twitter.com/headtagmx"
            >
                <g className="red">
                    <path d="M331.56,0a25.1,25.1,0,1,0,25.1,25.09A25.09,25.09,0,0,0,331.56,0Zm13.7,17.11c-.66.67-1.35,1.3-2,1.94a.49.49,0,0,0-.19.42A17.08,17.08,0,0,1,339,31a15.59,15.59,0,0,1-9.82,5.59,16.93,16.93,0,0,1-11-1.8l-.82-.49-.17-.12a12.26,12.26,0,0,0,4.59-.38,11.63,11.63,0,0,0,4.09-2.07,6,6,0,0,1-5.5-4.1,6.21,6.21,0,0,0,2.61-.11,6,6,0,0,1-3.79-2.68,5.72,5.72,0,0,1-.89-3.17,6.26,6.26,0,0,0,2.64.71,5.89,5.89,0,0,1-2.55-4.27,5.71,5.71,0,0,1,.76-3.59,16.87,16.87,0,0,0,12.16,6.17c0-.35-.09-.67-.1-1a5.78,5.78,0,0,1,4-5.95A5.7,5.7,0,0,1,341.12,15a.66.66,0,0,0,.71.19A12.92,12.92,0,0,0,344.89,14l.28-.14A6.18,6.18,0,0,1,342.74,17a12.45,12.45,0,0,0,3.18-.79A7.1,7.1,0,0,1,345.26,17.11Z" />
                </g>
            </a>
            <a
                xlinkHref="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
                href="https://www.youtube.com/channel/UC0O12wNOfI9-e9wxAI_72QA"
            >
                <g className="red">
                    <path d="M267.15,29.71l7.57-4.61-7.57-4.61Zm4.33-6.48v.05h0S271.48,23.25,271.48,23.23Z" />
                    <path d="M270,0a25.1,25.1,0,1,0,25.1,25.09A25.09,25.09,0,0,0,270,0Zm15.18,29.78A6.09,6.09,0,0,1,280,35.22a87.23,87.23,0,0,1-10.58.53c-2.64,0-5.84-.16-9-.46a6.09,6.09,0,0,1-5.6-5.75,66.81,66.81,0,0,1,0-9.17A6.08,6.08,0,0,1,260,15a76.09,76.09,0,0,1,10.88-.51q4.36.12,8.72.45a6,6,0,0,1,5.6,5.75A67.85,67.85,0,0,1,285.18,29.78Z" />
                </g>
            </a>
            <a
                xlinkHref="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
                href="https://open.spotify.com/show/7y78XznP9UlC2WNlZvNDxQ?si=26FPynBSRRi6Ra1EqoJ7gw"
            >
                <g className="red">
                    <path d="M400.39,26.42a21.41,21.41,0,0,0-9.71-2.33,22.36,22.36,0,0,0-5.28.69,1.5,1.5,0,0,0-1,1.8,1.1,1.1,0,0,0,1.31.84l.39-.09a18.82,18.82,0,0,1,8.38-.24,18.44,18.44,0,0,1,4.94,1.71c.67.34,1,.26,1.39-.36l.15-.23A1.13,1.13,0,0,0,400.39,26.42Z" />
                    <path d="M398.72,31.26a16.92,16.92,0,0,0-7.94-2h0A17.12,17.12,0,0,0,386,30a1.5,1.5,0,0,0-.7,2.06.91.91,0,0,0,.95.38l.46-.12a14.77,14.77,0,0,1,6.51-.37,15.44,15.44,0,0,1,4.76,1.63.93.93,0,0,0,1.32-.32A1.54,1.54,0,0,0,398.72,31.26Z" />
                    <path d="M402.22,21.25A26.49,26.49,0,0,0,390,18.56a24.66,24.66,0,0,0-5.46.75,1.37,1.37,0,0,0-1,1.66,1.24,1.24,0,0,0,1.55,1l.59-.13a23.29,23.29,0,0,1,15.42,1.88,1.12,1.12,0,0,0,1.08,0,1.42,1.42,0,0,0,.81-1.28A1.25,1.25,0,0,0,402.22,21.25Z" />
                    <path d="M392.91,0A25.1,25.1,0,1,0,418,25.09,25.09,25.09,0,0,0,392.91,0Zm-.32,38.88a13.79,13.79,0,1,1,14.07-13.74A13.81,13.81,0,0,1,392.59,38.88Z" />
                </g>
            </a>
        </svg>
    );
};
export default Redes;
