import React, { Component } from "react";
import { Route } from "react-router-dom";
import { NavBar } from "./NavBar";

export default class EnhancedRoute extends Component {
    render() {
        const { path, exact, component, withNavBar } = this.props;
        if (withNavBar) {
            return (
                <Route
                    path={path}
                    exact={exact}
                    render={props => (
                        <>
                            <NavBar
                                history
                                transparent
                                actualSection={"Inicio"}
                            />
                            {React.createElement(component, props)}
                        </>
                    )}
                />
            );
        }
        return <Route path={path} exact={exact} component={component} />;
    }
}
