import { Component, Fragment } from "react";
import React from "react";
import { INavBarProps } from "../types/SiteTypes";
import { routes } from "../routes/Routes";
import logo from "../images/logo.svg";
import hamburger from "../images/hamburger.svg";
import Button from "./Button";
import { Desktop, TabletandMobile } from "./Responsive";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { bubble as Menu } from "react-burger-menu";

import "../css/navbar.scss";

export class NavBar extends Component<INavBarProps> {
    state = { isOpen: false };
    render() {
        return (
            <>
                <div id="header">
                    <div className="burger-container">
                        <TabletandMobile>
                            <img
                                src={hamburger}
                                className="hamburger"
                                alt="hamburger menu"
                                onClick={() =>
                                    this.setState({
                                        isOpen: !this.state.isOpen
                                    })
                                }
                            />
                        </TabletandMobile>
                        <Link
                            className="logo-container"
                            to={routes.Inicio.link}
                        >
                            <img
                                src={logo}
                                className="headtag-logo"
                                alt="Headtag mx"
                            />
                        </Link>
                    </div>
                    <Desktop>
                        <Fragment>
                            <nav>
                                <ol>
                                    <li className="servicios-list">
                                        <HashLink to={routes.Servicios.link}>
                                            {routes.Servicios.name}
                                        </HashLink>
                                        <div className="dropdown-servicios animated fadeIn faster">
                                            <Link
                                                to={
                                                    routes["desarrollo-web"]
                                                        .link
                                                }
                                            >
                                                {routes["desarrollo-web"].name}
                                            </Link>
                                            <Link to={routes["seo-sem"].link}>
                                                {routes["seo-sem"].name}
                                            </Link>
                                            <Link
                                                to={
                                                    routes["diseno-grafico"]
                                                        .link
                                                }
                                            >
                                                {routes["diseno-grafico"].name}
                                            </Link>
                                            <Link
                                                to={
                                                    routes[
                                                        "community-management"
                                                    ].link
                                                }
                                            >
                                                {
                                                    routes[
                                                        "community-management"
                                                    ].name
                                                }
                                            </Link>
                                            <Link to={"/hectorol"}>
                                                Ilustracion
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to={routes.Logros.link}>
                                            {routes.Logros.name}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={routes.Cultura.link}>
                                            {routes.Cultura.name}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={routes.Blog.link}>
                                            {routes.Blog.name}
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                            <div className="button-iniciar">
                                <Button
                                    color={"rojo"}
                                    action={"/inicia-tu-proyecto"}
                                    tamano={"p-medium"}
                                    onClick={() =>
                                        this.setState({ isOpen: false })
                                    }
                                >
                                    Inicia tu proyecto
                                </Button>
                            </div>
                        </Fragment>
                    </Desktop>
                </div>
                <TabletandMobile>
                    {this.state.isOpen ? (
                        <Menu
                            isOpen={this.state.isOpen}
                            customBurgerIcon={false}
                            onStateChange={(isOpenChange: any) => {
                                if (isOpenChange.isOpen === false)
                                    this.setState({
                                        isOpen: isOpenChange.isOpen
                                    });
                            }}
                        >
                            <a
                                href={routes.Servicios.link}
                                onClick={() => this.setState({ isOpen: false })}
                            >
                                <h3>{routes.Servicios.name}</h3>
                            </a>
                            <a
                                href={routes.Logros.link}
                                onClick={() => this.setState({ isOpen: false })}
                            >
                                <h3>{routes.Logros.name}</h3>
                            </a>
                            <a
                                href={routes.Cultura.link}
                                onClick={() => this.setState({ isOpen: false })}
                            >
                                <h3>{routes.Cultura.name}</h3>
                            </a>
                            <a
                                href={routes.Blog.link}
                                onClick={() => this.setState({ isOpen: false })}
                            >
                                <h3>{routes.Blog.name}</h3>
                            </a>

                            <Button
                                color={"rojo"}
                                action={"/inicia-tu-proyecto"}
                                onClick={() => this.setState({ isOpen: false })}
                            >
                                Inicia tu proyecto
                            </Button>
                        </Menu>
                    ) : (
                        <></>
                    )}
                </TabletandMobile>
            </>
        );
    }
}
