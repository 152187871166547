import React, { Component, Suspense } from "react";
import { Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { createBrowserHistory } from "history";
import { EntradaBlog } from "./EntradaBlog";
import Fallback from "./Fallback";
import Landing from "./Landing";
import EnhancedRoute from "./EnhancedRoute";
import Felicidades from "./Felicidades";

const Hector = React.lazy(() => import("./Hector"));
const Cultura = React.lazy(() => import("./Cultura"));
const Logros = React.lazy(() => import("./Logros"));
const Inicio = React.lazy(() => import("./Inicio"));
const Blog = React.lazy(() => import("./Blog"));
const IniciaTuProyecto = React.lazy(() => import("./IniciaTuProyecto"));
const Servicio = React.lazy(() => import("./Servicio"));
const Doctores = React.lazy(() => import("./Doctores"));

class App extends Component {
    render = () => {
        let history = createBrowserHistory();
        return (
            <Router history={history}>
                <ScrollToTop>
                    <Suspense fallback={<Fallback />}>
                        <Switch>
                            <EnhancedRoute
                                path="/"
                                exact={true}
                                component={Inicio}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/blog"
                                exact
                                component={Blog}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/logros"
                                exact
                                component={Logros}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/cultura"
                                exact
                                component={Cultura}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/blog/:entrada"
                                component={EntradaBlog}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/servicios/:servicio"
                                component={Servicio}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/inicia-tu-proyecto"
                                component={IniciaTuProyecto}
                                withNavBar={true}
                            />
                            <EnhancedRoute
                                path="/hectorol"
                                component={Hector}
                                withNavBar={true}
                            />
                            <Route
                                path="/landings/doctores"
                                component={Doctores}
                            />
                            <Route
                                path="/landings/diseno"
                                component={Landing}
                            />
                            <Route path="/landings/felicidades">
                                <Felicidades
                                    doctores={true}
                                    blanco={false}
                                    regresar={false}
                                />
                            </Route>
                            <Route path="/LosCochinitos/hd" exact>
                                <img
                                    alt="Menú los cochinitos"
                                    src={`https://headtag.info/LosCochinitos/carta-hd.jpg?${new Date()}`}
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        display: "block"
                                    }}
                                />
                                <img
                                    alt="Menú desayunos los cochinitos"
                                    src={`https://headtag.info/LosCochinitos/desayunos-hd.jpg?${new Date()}`}
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        display: "block"
                                    }}
                                />
                            </Route>
                            <Route path="/LosCochinitos" exact>
                                <>
                                    <img
                                        alt="Menú los cochinitos"
                                        src={`https://headtag.info/LosCochinitos/carta.jpg?${new Date()}`}
                                        style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "auto",
                                            display: "block"
                                        }}
                                    />
                                    <img
                                        alt="Menú desayunos los cochinitos"
                                        src={`https://headtag.info/LosCochinitos/desayunos.jpg?${new Date()}`}
                                        style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "auto",
                                            display: "block"
                                        }}
                                    />
                                </>
                            </Route>
                            <EnhancedRoute
                                path="/:entrada"
                                component={EntradaBlog}
                                withNavBar={true}
                            />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Router>
        );
    };
}

export default App;
